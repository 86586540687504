html, body, #root {
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans JP', 'Noto Sans', sans-serif;
}

.furigana {
  font-size: 50%;
  text-align: center;
}

.furiganaHidden .furigana {
  visibility: hidden;
}

.surface {
  text-align: center;
}

.clickable:hover {
  background-color: rgba(162, 244, 255, 0.75);
  cursor: pointer;
}

.bookmark {
  border-bottom: 1px solid #000;
  font-weight: bold;
}

@media screen {
  .a4 {
    width: calc(21cm * 0.9);
    height: calc(29.7cm * 0.9);
    display: block;
    box-shadow: 0 0 0.1cm rgba(0,0,0,0.5);
    border: 0px;
  }
  .a4 > div {
    padding: 0.2cm 0.2cm;
  }
  .pageContentSetting {
    display: flex;
    align-items: center;
    margin: 6px 0px;
  }
  .pageContentSetting > div {
    margin: 0px 6px;
    display: flex;
    align-items: center;
  }
}


@page {
  size: A4;
  margin: 0.0cm 0.0cm;
}
@media print {
  form {
    display: none;
  }
  .pageContentSetting {
    display: none;
  }
  .content {
    margin: 0px;
  }
}
